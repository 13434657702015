import { range, every } from "lodash-es"
import { DAY_TIME_SLOT_TYPE } from "./../../BasicSettings/constants"
import { compactValues } from "./helpers"

export const mustBeInAscOrder = (value, self) => {
  if (self.type === DAY_TIME_SLOT_TYPE) {
    return true
  }
  const hours = compactValues(value)
  return every(range(1, hours.length), index => hours[index - 1] < hours[index])
}

export const atLeastOneValue = (value, self) => {
  if (self.type === DAY_TIME_SLOT_TYPE) {
    return true
  }

  return compactValues(value).length > 0
}
