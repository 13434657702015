import { DAY_TIME_SLOT_TYPE } from "./../constants"
import { compact, map, range } from "lodash-es"

export const defaultTimeSlotObject = () => ({
  code: null,
  name: null,
  type: DAY_TIME_SLOT_TYPE,
  until_day: 1,
  hours: range(1, 11).map(() => ({ value: null }))
})

export const compactValues = (array, valueKey = "value") => compact(map(array, valueKey))
