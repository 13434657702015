<template lang="pug">
  .time-division-form
    ArrowBack(@back="$emit('back')")
    .title
      span {{ $t("company_system.basic_settings.time_division.details") }}
    .form
      .form-row
        .form-row-label
          FormFieldLabel(:title="$t('company_system.basic_settings.time_division.code')")
        .form-row-field
          BFormInput(
            type="text"
            name="code"
            v-model="timeSlot.code"
            :placeholder="$t('company_system.basic_settings.time_division.code')"
          )
      .form-row
        .form-row-label
          FormFieldLabel(:title="$t('company_system.basic_settings.time_division.name')")
          span.required {{ $t('company_system.req') }}
        .form-row-field
          BFormInput(
            type="text"
            name="name"
            :class="{ invalid: $v.timeSlot.name.$error }"
            v-model="timeSlot.name"
            :placeholder="$t('company_system.basic_settings.time_division.name')"
          )
      .form-row
        .form-row-label
          FormFieldLabel(:title="$t('company_system.basic_settings.time_division.time_division_system')")
        .form-row-field.system-types
          AppDropdown(
            close-on-select
            allow-empty
            value-key="type"
            title-key="name"
            :value="selectedSlotType"
            :items="slotTypes"
            @select="selectSlotType"
          )
        .time-system-options(v-if="isTimeSlotTypeSelected")
          .time-system-option.flex-row(
            v-for="inputNumber in hourInputsNumber"
            :key="inputNumber"
          )
            BFormInput(
              type="number"
              name="time-system-option"
              :disabled="isInputDisabled(inputNumber - 1)"
              :class="{ invalid: showInputError(inputNumber - 1) }"
              :value="getHourValue(inputNumber - 1)"
              @input="handleHourInput(inputNumber - 1, $event)"
            )
            .label
              span {{ $t('company_system.basic_settings.time_division.until_hour') }}
          .time-system-tooltip
            span {{ $t('company_system.basic_settings.time_division.system_tooltip') }}
        .calendar-day-system-options(v-else)
          .calendar-day-system-option
            AppDropdown(
              order-direction="keep"
              close-on-select
              allow-empty
              value-key="day"
              title-key="name"
              :value="selectedUntilDay"
              :items="untilDays"
              @select="setUntilDay"
            )
    FormActionBar(
      :delete-enabled="isEdit && !timeSlot.unremovable"
      @delete="removeTimeSlot"
      @save="handleSave"
    )
</template>

<script>
  // misc
  import { range, find, merge } from "lodash-es"
  import { defaultTimeSlotObject } from "./helpers"
  import { mustBeInAscOrder, atLeastOneValue } from "./validators"
  import { DAY_TIME_SLOT_TYPE, HOURS_TIME_SLOT_TYPE, HOUR_INPUTS_NUMBER } from "./../constants"

  // store modules
  import timeSlotsModule from "@/config/store/company_system/basic_settings/time_slots"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import withValidations from "@/mixins/withValidations"
  import withScrollTop from "@/mixins/withScrollTop"
  import withConfirmation from "@/mixins/withConfirmation"

  const timeSlotsMixin = withStoreModule(timeSlotsModule, {
    name: "timeSlots",
    readers: {
      timeSlotItem: "item",
      timeSlotLoading: "loading"
    },
    actions: {
      fetchTimeSlot: "FETCH_ITEM",
      createTimeSlot: "CREATE_ITEM",
      updateTimeSlot: "UPDATE_ITEM",
      deleteTimeSlot: "DELETE_ITEM"
    }
  })

  const validationsMixin = withValidations(({ required, minLength, requiredIf }) => ({
    timeSlot: {
      name: {
        required,
        minLength: minLength(1)
      },
      type: { required },
      until_day: {
        required: requiredIf(function(nested) {
          return nested.type === DAY_TIME_SLOT_TYPE
        })
      },
      hours: {
        required: requiredIf(function(nested) {
          return nested.type === HOURS_TIME_SLOT_TYPE
        }),
        mustBeInAscOrder,
        atLeastOneValue
      }
    }
  }))

  export default {
    components: {
      ArrowBack: () => import("@/components/CompanySystem/ArrowBack"),
      FormFieldLabel: () => import("@/components/elements/FormFieldLabel"),
      FormActionBar: () => import("../../FormActionBar"),
      AppDropdown: () => import("@/components/elements/AppDropdown")
    },

    mixins: [timeSlotsMixin, validationsMixin, withScrollTop, withConfirmation],

    props: {
      timeSlotId: {
        type: Number,
        required: false
      }
    },

    data() {
      return {
        timeSlot: defaultTimeSlotObject(),
        hourInputsNumber: HOUR_INPUTS_NUMBER
      }
    },

    mounted() {
      if (this.isEdit) {
        this.fetchTimeSlot(this.timeSlotId).then(() => {
          this.timeSlot = merge(this.timeSlot, this.timeSlotItem)
        })
      }
    },

    methods: {
      isInputDisabled(index) {
        return index > 0 && this.timeSlot.hours[index - 1]?.value === null
      },

      showInputError(index) {
        return !this.isInputDisabled(index) && this.hasErrorInHours
      },

      selectSlotType({ type }) {
        this.timeSlot.type = type
      },

      setUntilDay({ day }) {
        this.timeSlot.until_day = day
      },

      getHourValue(index) {
        return this.timeSlot.hours[index]?.value
      },

      handleHourInput(index, value) {
        this.timeSlot.hours[index].value = Number(value)
      },

      handleSave() {
        this.validateAttributes()
        if (!this.isValid) {
          this.$nextTick(() => {
            this.scrollTo({ target: ".invalid", inline: "center" })
          })
          return
        }
        this.sendRequest().then(() => {
          this.cancelValidation()
          this.$emit("back")
        })
      },

      async sendRequest() {
        if (this.isEdit) {
          await this.updateTimeSlot(this.timeSlot)
        } else {
          await this.createTimeSlot(this.timeSlot)
        }
      },

      removeTimeSlot() {
        this.$confirm({
          title: this.$t("company_system.basic_settings.delete_time_slot_confirmation"),
          resolve: {
            handler: () => {
              this.deleteTimeSlot(this.timeSlotId).then(() => {
                this.$emit("back")
              })
            }
          }
        })
      }
    },

    computed: {
      isEdit() {
        return !!this.timeSlotId
      },

      hasErrorInHours() {
        return this.$v.timeSlot.hours.$error
      },

      isTimeSlotTypeSelected() {
        return this.timeSlot.type === HOURS_TIME_SLOT_TYPE
      },

      selectedSlotType() {
        return find(this.slotTypes, { type: this.timeSlot.type })
      },

      selectedUntilDay() {
        return find(this.untilDays, { day: this.timeSlot.until_day })
      },

      slotTypes() {
        return [
          {
            type: HOURS_TIME_SLOT_TYPE,
            name: this.$t("company_system.basic_settings.time_division.slot_type.hours")
          },
          {
            type: DAY_TIME_SLOT_TYPE,
            name: this.$t("company_system.basic_settings.time_division.slot_type.day")
          }
        ]
      },

      untilDays() {
        return range(1, 11).map(day => ({
          day: day,
          name: this.$t("company_system.basic_settings.time_division.until_day", { day: day })
        }))
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/price-management/basic-settings.sass"
  @import "@/assets/styles/matchings.sass"
  @import "@/assets/styles/variables.sass"

  .time-division-form
    +styled-inputs

    input
      height: 38px

      &.invalid
        +default-invalid-input

    margin-bottom: 20px

    .flex-row
      display: flex
      justify-content: left

    .arrow-back
      cursor: pointer
      margin-left: 5px
      margin-top: 10px

      ::v-deep
        svg
          path
            fill: $default-gray-medium

      span
        vertical-align: text-top
        margin-left: 5px
        color: $default-purple
        font-size: 0.9rem

    .title
      margin-top: 20px
      height: 40px
      border-left: 13px solid $default-purple-light
      padding: 10px
      font-weight: 700
      font-size: 16px

    .form
      padding: 5px

      &-row
        display: flex
        flex-direction: column
        margin-top: 20px

        &-label
          font-style: normal
          font-weight: 200
          font-size: 13px
          line-height: 19px

          .app-tooltip
            ::v-deep
              svg
                vertical-align: text-bottom
                width: 17px
                height: 17px
                path
                  fill: $default-turquoise-medium

          span.required
            margin-left: 10px
            font-size: 12px
            color: $default-red

        &-field
          margin-top: 5px
          font-weight: 400
          font-size: 16px

          &.system-types
            .app-select
              padding: 0

        .time-system-options
          margin-top: 5px

          .time-system-tooltip
            font-size: 14px

          .time-system-option
            margin-bottom: 5px

            input
              text-align: center
              width: 48px

            .label
              padding: 7px
              font-size: 15px

        .calendar-day-system-options
          margin-top: 5px

          .calendar-day-system-option
            .app-select
              padding: 0
</style>
